import React, { useState, useEffect } from 'react'
import { Modal, ModalHeader, ModalFooter, ModalBody, Button, Col, Input, Row, Table } from 'reactstrap'
import { FaTimes, FaPlus } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import "../../../assets/scss/plugins/extensions/toastr.scss"
import "react-toggle/style.css"
import "react-toastify/dist/ReactToastify.css"
import "../../../assets/scss/plugins/extensions/toastr.scss"
import API from '../../../providers/api';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';

export const ModalNoteColumns = ({ showModal, setShowModal }) => {
    const user_id = useSelector(state => state.auth.login.state.data.user.verifyLogin.id);
    const [loading, setLoading] = useState(false)
    const [form, setForm] = useState('')
    const [lastUpdate, setLastUpdate] = useState('')

    const [tableColumns, setTableColumns] = useState([{column_1: "", column_2: "", column_3: "", column_4: "", column_5: "", column_6: "", column_8: "", column_9: "", column_10: "", column_11: "", column_12: "", orderby: 1}])
    const columns = ["Column 1", "Column 2", "Column 3", "Column 4", "Column 5", "Column 6", "Column 7", "Column 8", "Column 9", "Column 10", "Column 11", "Column 12","orderby"]
    const valueColumns = ["column_1", "column_2", "column_3", "column_4", "column_5", "column_6", "column_7", "column_8", "column_9", "column_10", "column_11", "column_12", "orderby"]

    const addRow = () => {
        const item = { column_1: "", column_2: "", column_3: "", column_4: "", column_5: "", column_6: "", column_8: "", column_9: "", column_10: "", column_11: "", column_12: "", orderby: tableColumns.length+1 }
        setTableColumns([...tableColumns, item])
    }

    const removeRows = (idx) => {
        if(tableColumns.length > 1){
            const tempRows = [...tableColumns]
            tempRows.splice(idx, 1)
            setTableColumns(tempRows)
        }
    }

    const onchangeTableColumns = (e, index) => {
        let prope = e.target.attributes.column.value;; // the custom column attribute
        let fieldValue = e.target.value; // value
        const tempRows = [...tableColumns]; // avoid direct state mutation
        const tempObj = tableColumns[index]; // copy state object at index to a temporary object
        tempObj[prope] = fieldValue; // modify temporary object
        tempRows[index] = tempObj;
        console.log(tempRows, "tempRows www");

        setTableColumns(tempRows); // update state
    }

    const toggleModal = () => {
    setShowModal(!showModal)
    }

    useEffect(() => {
    searchNoteColumns(user_id)
    }, [])

const searchNoteColumns = (user_id) => {
    API.searchNoteColumns(user_id)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
            setTableColumns(dataJson.data)
        }
    })
    .catch((e) => {
        console.log("Error =>", e)
    })
}

const save = async () => {
    setLastUpdate('')
    API.saveNoteColumns(user_id, tableColumns)
    .then(data => data.json())
    .then(dataJson => {
        if (dataJson.success === true) {
            setForm(dataJson.data)
            setLastUpdate(`Last update: ${moment().format('MM-DD-YYYY HH:mm:ss')}`)
            toast.success('Columns update successfully');
        }
    })
    .catch((e) => {
        console.log("Error =>", e)
    })
}

const toggleClose = () => setShowModal(!showModal)

return (
<Modal size='xl' isOpen={showModal} toggle={toggleClose}>
    <ToastContainer />
    <ModalHeader toggle={toggleClose}>
    My notes columns
    </ModalHeader>
    <form onSubmit={save}>
    <ModalBody>
        <Row className='mb-1'>
            <Col lg="2">
                <Button size="sm" color="info" onClick={addRow}><FaPlus /></Button>
            </Col>
        </Row>
        <Row>
            <Col md="12" >
                <Table>
                    <tbody>
                        {
                            tableColumns.map((item, idx) => (
                                <tr key={idx}>
                                    <td hidden>{idx + 1}</td>
                                    {
                                        valueColumns.map((column, index) => (
                                            <td key={index} style={{padding:'0'}}>
                                                {
                                                    <Input disabled={column == "orderby" ? true : false}
                                                    type='text'
                                                    column={column}
                                                    value={tableColumns[idx][column]}
                                                    index={idx}
                                                    onChange={(e) => onchangeTableColumns(e, idx)}
                                                />
                                                }
                                            </td>
                                        ))
                                    }
                                    <td> {idx != 0 ? <FaTimes size="12" color="red" onClick={() => removeRows(idx)} /> : ''} </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </Table>
            </Col>
        </Row>
        <Row>
            <Col lg="2">
                <Button size="sm" color="info" onClick={addRow}><FaPlus /></Button>
            </Col>
        </Row>
        <br/>
        {lastUpdate}
    </ModalBody>
    <ModalFooter>
        <Button color="info" onClick={save}>Save</Button>{" "}
        <Button outline color="success" onClick={toggleModal} disabled={loading}>Closed</Button>{" "}
    </ModalFooter>
    </form>
</Modal>
)
}
